import React from "react"
import styled from "styled-components"
import { useSettingContext } from "../settingsContext"
import { navigate, Link } from "gatsby"
import media from "../media"
import { gutter } from "../vars"

const flags = {
  no: (
    <svg
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="48"
      viewBox="0 0 64 48"
    >
      <g fill="none" fillRule="evenodd">
        <rect width="64" height="48" fill="#EC1C24" rx="4" />
        <path fill="#FFF" d="M0 20h64v8H0z" />
        <path fill="#FFF" d="M24 0h8v48h-8z" />
        <path fill="#0161C1" d="M0 22h64v4H0z" />
        <path fill="#0161C1" d="M26 0h4v48h-4z" />
        <rect
          width="62"
          height="46"
          x="1"
          y="1"
          stroke="#000"
          strokeOpacity=".1"
          strokeWidth="2"
          rx="4"
        />
      </g>
    </svg>
  ),
  sv: (
    <svg
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="48"
      viewBox="0 0 64 48"
    >
      <g fill="none" fillRule="evenodd">
        <rect width="64" height="48" fill="#0161C1" rx="4" />
        <path fill="#FC0" d="M0 20h64v8H0z" />
        <path fill="#FC0" d="M24 0h8v48h-8z" />
        <rect
          width="62"
          height="46"
          x="1"
          y="1"
          stroke="#000"
          strokeOpacity=".1"
          strokeWidth="2"
          rx="4"
        />
      </g>
    </svg>
  ),
  en: (
    <svg
      preserveAspectRatio="none"
      width="44px"
      height="32px"
      viewBox="0 0 44 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <rect id="path-111" x="0" y="0" width="44" height="32" rx="3"></rect>
      </defs>
      <g
        id="Web-Icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="WebIcons" transform="translate(-163.000000, -525.000000)">
          <g id="Web/Flags/UK" transform="translate(163.000000, 525.000000)">
            <g>
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-111"></use>
              </mask>
              <use id="Mask" fill="#FFFFFF" xlinkHref="#path-111"></use>
              <rect
                id="Mask-Copy"
                fill="#0A17A7"
                mask="url(#mask-2)"
                x="0"
                y="0"
                width="44"
                height="32"
              ></rect>
              <path
                d="M10.4873077,21.3333333 L-1.77635684e-15,21.3333333 L-1.77635684e-15,10.6666667 L10.4873077,10.6666667 L-4.35859329,0.470913007 L-2.01530874,-3.06631397 L16.7619048,9.82935629 L16.7619048,-2.13333333 L27.2380952,-2.13333333 L27.2380952,9.82935629 L46.0153087,-3.06631397 L48.3585933,0.470913007 L33.5126923,10.6666667 L44,10.6666667 L44,21.3333333 L33.5126923,21.3333333 L48.3585933,31.529087 L46.0153087,35.066314 L27.2380952,22.1706437 L27.2380952,34.1333333 L16.7619048,34.1333333 L16.7619048,22.1706437 L-2.01530874,35.066314 L-4.35859329,31.529087 L10.4873077,21.3333333 Z"
                id="Rectangle-2"
                fill="#FFFFFF"
                mask="url(#mask-2)"
              ></path>
              <path
                d="M29.3353795,10.1315104 L49.2380952,-3.2"
                id="Line"
                stroke="#DB1F35"
                strokeWidth="1.04761905"
                strokeLinecap="round"
                mask="url(#mask-2)"
              ></path>
              <path
                d="M31.4486491,34.1605469 L49.2903995,21.9160156"
                id="Line-Copy-2"
                stroke="#DB1F35"
                strokeWidth="1.04761905"
                strokeLinecap="round"
                mask="url(#mask-2)"
                transform="translate(40.369524, 28.038281) scale(1, -1) translate(-40.369524, -28.038281) "
              ></path>
              <path
                d="M12.5801246,10.0967448 L-6.03033157,-2.67356771"
                id="Line-Copy"
                stroke="#DB1F35"
                strokeWidth="1.04761905"
                strokeLinecap="round"
                mask="url(#mask-2)"
              ></path>
              <path
                d="M14.5986328,35.6967448 L-6.03033157,21.7678385"
                id="Line-Copy-3"
                stroke="#DB1F35"
                strokeWidth="1.04761905"
                strokeLinecap="round"
                mask="url(#mask-2)"
                transform="translate(4.284151, 28.732292) scale(1, -1) translate(-4.284151, -28.732292) "
              ></path>
              <polygon
                id="Rectangle-2-Copy-3"
                fill="#E6273E"
                mask="url(#mask-2)"
                points="0 19.2 18.8571429 19.2 18.8571429 32 25.1428571 32 25.1428571 19.2 44 19.2 44 12.8 25.1428571 12.8 25.1428571 0 18.8571429 0 18.8571429 12.8 0 12.8"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
}

const Dropdown = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 10;
  display: none;
  width: 200px;
  padding-top: 8px;

  ${media.mobileMenu`
    display: none !important;
  `}
`

const Container = styled.div`
  position: relative;
  margin-left: ${gutter * 1}px;

  ${media.mobileMenu`
  font-size: 22px;
  margin-left: 0
  margin-top: -${gutter / 2}px;;

     opacity: ${p => (p.show ? 1 : 0)};
    transition: opacity 0.4s ease;  
    transition-delay: ${p => p.delay}ms;
    

  `}

  &:hover {
    ${Dropdown} {
      display: block;
    }
  }
`
const Selection = styled.div`
  display: flex;
  align-items: center;
`
const Flag = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 8px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`

const Option = styled(Link)`
  padding: 4px 8px;
  cursor: pointer;
  background: #07746c;
  display: block;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: normal;

  transition: background 0.3s ease, color 0.3s ease;

  ${Flag} {
    margin: 0;
    margin-right: 8px;
  }

  &:hover {
    background: white;
    color: #07746c;
  }
`
const Select = styled.select`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: none;

  @media (min-width: 1430px) {
  }

  ${media.mobileMenu`
    display: block;
  `}
`

const LanguageSelect = ({ delay, show, onSelect }) => {
  const t = useSettingContext()

  const selected = t.lang
  const options = [
    {
      value: "no",
      text: t("norwegian"),
    },
    {
      value: "sv",
      text: t("swedish"),
    },
    {
      value: "en",
      text: t("english"),
    },
  ]

  const goTo = val => navigate(t.translate(val))
  const onChange = e => {
    const { value } = e.target
    navigate(t.translate(value))
    onSelect()
  }

  const currentFlag = flags[selected]
  return (
    <Container delay={delay} show={show}>
      <Selection>
        {t("select_language")}
        <Flag>{currentFlag}</Flag>
        <Select value={selected} onChange={onChange}>
          {options.map(op => (
            <option key={op.value} value={op.value}>
              {op.text}
            </option>
          ))}
        </Select>
        <Dropdown>
          {options.map(op => (
            <Option to={t.translate(op.value)} key={op.value}>
              <Flag>{flags[op.value]}</Flag>
              {op.text}
            </Option>
          ))}
        </Dropdown>
      </Selection>
    </Container>
  )
}

export default LanguageSelect
