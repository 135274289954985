import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import logo from "../logo.png"
import { useSettingContext } from "../settingsContext"
import { gutter, primaryBg } from "../vars"
import Grid from "./Grid"
import { useStaticQuery, graphql, Link } from "gatsby"
import get from "lodash/get"
import { getIdFromString } from "../utils"
import Burger from "./Burger"
import media from "../media"
import LanguageSelect from "./LanguageSelect"

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${gutter * 5}px 0;
  position: relative;
  z-index: 50;

  ${media.tablet`
  padding: ${gutter * 3}px 0;

  `}
  ${media.mobileMenu`
    padding: ${gutter * 1}px 0;
    position: sticky;
    top:0;
  `}
`

const Logo = styled(Link)`
  max-width: 200px;

  ${media.tablet`
     max-width: 100px;
  `}
  ${media.mobileMenu`
    max-width: 70px;
  `}
`
const MenuItem = styled.span`
  color: white;
  text-decoration: none;
  margin-left: ${gutter * 1.5}px;
  position: relative;
  cursor: pointer;
  padding-bottom: ${gutter / 2}px;
  font-weight: normal;

  ${media.mobileMenu`
    font-size: 22px;
    padding: 5px 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(${p => (p.show ? -10 : 0)}px);
    opacity: ${p => (p.show ? 1 : 0)};
    
  `}

  &:after {
    content: "";
    width: 100%;
    height: 2px;
    background: white;
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    opacity: 0;
    transform: translateY(2px) scaleX(0.9);
    transition: opacity 0.4s ease-in, transform 0.4s ease-in;
  }

  &:hover:after {
    transform: translateY(0px) scaleX(1);
    opacity: 1;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity:1;
  }
`

const Menu = styled.nav`
  margin-left: ${gutter * 2}px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  opacity: 0;
  position: relative;
  animation: ${fadeIn} 0.3s ease forwards;
  animation-delay: 0.3s;

  ${media.mobileMenu`
    animation: none;
    position: fixed;
    z-index:100;
    width:100%;
    height:100%;
    background: ${primaryBg};
    top:0;
    left:0;
    margin:0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: ${p => (p.show ? 1 : 0)};
    pointer-events: ${p => (p.show ? "all" : "none")};
    transition: opacity 0.4s ease;
    
  `};
`

const Header = ({ isFrontpage, noHeader }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const t = useSettingContext()

  const data = useStaticQuery(graphql`
    {
      allPrismicHome {
        nodes {
          lang
          data {
            blocks {
              block {
                document {
                  ... on PrismicSliderblock {
                    data {
                      menu_item
                      suffix {
                        document {
                          ... on PrismicApplinks {
                            data {
                              menu_item
                            }
                          }
                        }
                      }
                    }
                  }

                  ... on PrismicBlock {
                    data {
                      menu_item
                    }
                  }
                  ... on PrismicAppblock {
                    data {
                      menu_item
                    }
                  }
                  ... on PrismicLocationblock {
                    data {
                      menu_item
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [home] = get(data, "allPrismicHome.nodes", []).filter(
    node => node.lang === t.locale
  )
  const items =
    get(home, "data.blocks", [])
      .map(node => {
        const result = []
        const { menu_item, suffix } = get(node, "block.document[0].data", {})
        if (menu_item) {
          result.push(menu_item)
        }
        const appLinksMenuItem = get(suffix, "document[0].data.menu_item")
        if (appLinksMenuItem) {
          result.push(appLinksMenuItem)
        }
        return result
      })
      .filter(node => node.length > 0) || []

  const merged = items.reduce((acc, curr) => [...acc, ...curr], [])
  merged.push("FAQ")
  const closeMenu = () => {
    setMenuOpen(false)
  }
  const openMenu = () => {
    setMenuOpen(true)
  }
  const toggleMenu = () => {
    menuOpen ? closeMenu() : openMenu()
  }

  const getMenuItem = (item, i) => {
    const sharedProps = {
      show: menuOpen,
      onClick: closeMenu,
      style: {
        transitionDelay: !menuOpen ? "0s" : `${i * 55 + 200}ms`,
      },
      key: i,
    }

    if (item === "FAQ") {
      return (
        <MenuItem {...sharedProps} as={Link} to={t.url("/faq")}>
          {item}
        </MenuItem>
      )
    }

    const id = getIdFromString(item)

    if (!isFrontpage) {
      return (
        <MenuItem {...sharedProps} as={Link} to={`${t.url("/")}#${id}`}>
          {item}
        </MenuItem>
      )
    }

    const scrollTo = () => {
      const elem = document.getElementById(id)
      closeMenu()

      if (i === 0) {
        document
          .getElementsByTagName("body")[0]
          .scrollIntoView({ behavior: "smooth" })
      } else if (elem) {
        elem.scrollIntoView({ behavior: "smooth" })
      }
    }

    return (
      <MenuItem {...sharedProps} onClick={scrollTo}>
        {item}
      </MenuItem>
    )
  }

  return (
    <Grid>
      {noHeader || (
        <Wrapper>
          <Logo to={t.url("/")}>
            <img alt="logo" src={logo} />
          </Logo>
          <Burger active={menuOpen} onClick={toggleMenu} />
          <Menu show={menuOpen}>
            {merged.map(getMenuItem)}
            <LanguageSelect
              onSelect={closeMenu}
              delay={merged.length * 55 + 200}
              show={menuOpen}
            />
          </Menu>
        </Wrapper>
      )}
    </Grid>
  )
}

export default Header
