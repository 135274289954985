import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import Rellax from "rellax"
import media from "../media"

const Svg = () => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2771.03 1898.77"
  >
    <g className="cls-1">
      <rect
        className="cls-2"
        x="1852.55"
        y="890.58"
        width="367.11"
        height="50.31"
      />
      <rect
        className="cls-3"
        x="714.92"
        y="213.43"
        width="367.11"
        height="50.31"
        transform="translate(475.24 1082.03) rotate(-90)"
      />
      <rect
        className="cls-4"
        x="1249.27"
        y="1745.09"
        width="367.11"
        height="50.31"
        transform="translate(-522.07 3148.05) rotate(-90)"
      />
      <rect
        className="cls-5"
        x="155"
        y="1392.98"
        width="367.11"
        height="50.31"
      />
      <path
        className="cls-6"
        d="M2395.85,470.86c136.11,0,248.2,108.32,259.8,247h48.49A323.13,323.13,0,0,0,2614.73,516a307.84,307.84,0,0,0-98.27-68.58,301.17,301.17,0,0,0-241.22,0A307.81,307.81,0,0,0,2177,515.93a323.65,323.65,0,0,0-89.42,201.94h48.5C2147.66,579.18,2259.74,470.86,2395.85,470.86Z"
        transform="translate(-184.65 -55.03)"
      />
      <path
        className="cls-7"
        d="M742.61,532.12A217.75,217.75,0,0,0,541.3,399H184.65v48.27H541.3c93.82,0,170.15,76.14,170.15,170s-76.33,170-170.15,170H184.65v48.27H541.3A218.44,218.44,0,0,0,742.61,532.12Z"
        transform="translate(-184.65 -55.03)"
      />
      <path
        className="cls-8"
        d="M1591.38,669.41a217.75,217.75,0,0,0,133.1-201.31V111.45h-48.27V468.1c0,93.82-76.14,170.15-170,170.15s-170-76.33-170-170.15V111.45H1288V468.1a218.44,218.44,0,0,0,303.36,201.31Z"
        transform="translate(-184.65 -55.03)"
      />
      <path
        className="cls-9"
        d="M2186.56,1387.21a285.73,285.73,0,0,0-263.47-174.64,291.74,291.74,0,0,0-34.09,2v48.77a242.54,242.54,0,0,1,34.09-2.44c131,0,237.61,106.59,237.61,237.61s-106.59,237.69-237.61,237.69c-126.12,0-229.6-98.53-237.16-222.94h-48.42a286,286,0,1,0,549.05-126.06Z"
        transform="translate(-184.65 -55.03)"
      />
      <path
        className="cls-10"
        d="M888.21,991.6a285.75,285.75,0,0,0-172.05,265.18,296.67,296.67,0,0,0,2.34,34.07l48.78-.48a242.52,242.52,0,0,1-2.78-34.06c-1.29-131,104.25-238.65,235.27-239.94s238.72,104.25,240,235.27c1.23,126.11-96.28,230.55-220.61,239.33l.48,48.42A286,286,0,1,0,888.21,991.6Z"
        transform="translate(-184.65 -55.03)"
      />
      <path
        className="cls-11"
        d="M2555.84,1079.3a285.73,285.73,0,0,0-172.05,265.18,296.67,296.67,0,0,0,2.34,34.07l48.78-.48a242.66,242.66,0,0,1-2.78-34.07c-1.28-131,104.26-238.64,235.27-239.93s238.72,104.25,240,235.27c1.24,126.11-96.27,230.55-220.6,239.33l.47,48.42a286,286,0,1,0-131.44-547.79Z"
        transform="translate(-184.65 -55.03)"
      />
      <rect
        className="cls-12"
        x="301.85"
        y="222.31"
        width="2039.5"
        height="1359.67"
      />
    </g>
  </svg>
)

const Inner = styled.div`
  height: 100%;
  opacity: 0.7;
`
const Wrapper = styled.div`
  position: fixed;
  left: 0vh;
  top: 0vh;
  bottom: 0vh;
  right: 0vh;
  z-index: 1;
  overflow: hidden;
  pointer-events: none;
  background-image: linear-gradient(
    to right top,
    #008178,
    #008377,
    #008575,
    #008773,
    #008971,
    #008d6e,
    #00916b,
    #049567,
    #179c60,
    #2aa257,
    #3ca84c,
    #4eae3e
  );
  svg {
    transform: scale(1.3);
    width: 120%;

    ${media.phone`
      height: 150vh;
      width: auto;

    `}

    &:first-child {
      margin-bottom: 30vh;
    }

    .cls-10,
    .cls-11,
    .cls-2,
    .cls-3,
    .cls-4,
    .cls-5,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9 {
      opacity: 0.9;
    }
    .cls-2 {
      fill: url(#New_Gradient_Swatch_2);
    }
    .cls-3 {
      fill: url(#New_Gradient_Swatch_2-2);
    }
    .cls-4 {
      fill: url(#New_Gradient_Swatch_2-3);
    }
    .cls-5 {
      fill: url(#New_Gradient_Swatch_2-4);
    }
    .cls-6 {
      fill: url(#New_Gradient_Swatch_2-5);
    }
    .cls-7 {
      fill: url(#New_Gradient_Swatch_2-6);
    }
    .cls-8 {
      fill: url(#New_Gradient_Swatch_2-7);
    }
    .cls-9 {
      fill: url(#New_Gradient_Swatch_2-8);
    }
    .cls-10 {
      fill: url(#New_Gradient_Swatch_2-9);
    }
    .cls-11 {
      fill: url(#New_Gradient_Swatch_2-10);
    }
    .cls-12 {
      fill: none;
    }
  }
`
const Pattern = () => {
  const inner = useRef()
  useEffect(() => {
    const rellax = new Rellax(inner.current, {
      speed: 7,
    })

    return () => {
      rellax.destroy()
    }
  }, [])
  return (
    <Wrapper>
      <Inner ref={inner}>
        <Svg />
        <Svg />

        <svg>
          <defs>
            <clipPath id="clip-path" transform="translate(-184.65 -55.03)" />
            <linearGradient
              id="New_Gradient_Swatch_2"
              x1="2328.43"
              y1="915.74"
              x2="1923.1"
              y2="915.74"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#00d098" />
              <stop offset="1" stopColor="#b0d030" />
            </linearGradient>
            <linearGradient
              id="New_Gradient_Swatch_2-2"
              x1="898.48"
              y1="433.02"
              x2="898.48"
              y2="60.11"
              gradientTransform="translate(1137.06 -659.9) rotate(90)"
              xlinkHref="#New_Gradient_Swatch_2"
            />
            <linearGradient
              id="New_Gradient_Swatch_2-3"
              x1="1432.83"
              y1="1556.77"
              x2="1432.83"
              y2="1964.43"
              gradientTransform="translate(3203.08 337.42) rotate(90)"
              xlinkHref="#New_Gradient_Swatch_2"
            />
            <linearGradient
              id="New_Gradient_Swatch_2-4"
              x1="59.14"
              y1="1418.13"
              x2="527.95"
              y2="1418.13"
              xlinkHref="#New_Gradient_Swatch_2"
            />
            <linearGradient
              id="New_Gradient_Swatch_2-5"
              x1="2730.63"
              y1="570.04"
              x2="1993.56"
              y2="570.04"
              xlinkHref="#New_Gradient_Swatch_2"
            />
            <linearGradient
              id="New_Gradient_Swatch_2-6"
              x1="853.51"
              y1="590.02"
              x2="366.75"
              y2="617.22"
              xlinkHref="#New_Gradient_Swatch_2"
            />
            <linearGradient
              id="New_Gradient_Swatch_2-7"
              x1="1506.25"
              y1="955.13"
              x2="1506.25"
              y2="255.92"
              xlinkHref="#New_Gradient_Swatch_2"
            />
            <linearGradient
              id="New_Gradient_Swatch_2-8"
              x1="1923.28"
              y1="1136.54"
              x2="1923.28"
              y2="1892.56"
              xlinkHref="#New_Gradient_Swatch_2"
            />
            <linearGradient
              id="New_Gradient_Swatch_2-9"
              x1="677.57"
              y1="1040.07"
              x2="1221.44"
              y2="1352.79"
              xlinkHref="#New_Gradient_Swatch_2"
            />
            <linearGradient
              id="New_Gradient_Swatch_2-10"
              x1="2322.08"
              y1="1341.4"
              x2="3244.4"
              y2="1341.4"
              xlinkHref="#New_Gradient_Swatch_2"
            />
          </defs>
        </svg>
      </Inner>
    </Wrapper>
  )
}

export default Pattern
