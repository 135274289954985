import React from "react"
import styled from "styled-components"
import { useSettingContext } from "../settingsContext"
import { gutter } from "../vars"
import logo from "../logo.png"
import { useStaticQuery, graphql, Link } from "gatsby"
import media from "../media"

const Wrapper = styled.footer`
  padding: ${gutter * 4}px;
  position: relative;
  z-index: 2;
  text-align: center;
  font-size: 20px;

  a {
    color: white;
    text-decoration: none;
    line-height: 1.5;

    ${media.phone`
      padding: 20px 0;
    `}

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    padding: 0;
  }
  li {
    list-style-type: none;
    line-height: 1;
    padding: 10px 0;
  }
`
const Dot = styled.span`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background: white;
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px 4px 8px;
`

const Logo = styled.div`
  margin: ${gutter * 4}px auto;
  max-width: 200px;
`

const Footer = ({ noHeader }) => {
  const t = useSettingContext()
  const data = useStaticQuery(graphql`
    {
      allPrismicSettings {
        nodes {
          lang
          data {
            travel_conditions {
              uid
            }
            privacy_policy {
              uid
            }
          }
        }
      }
    }
  `)

  const node = data.allPrismicSettings.nodes.find(
    node => node.lang === t.locale
  )

  if (!node) return null

  const supportLink = noHeader
    ? t.url("/faqnoheader#support")
    : t.url("/faq#support")

  return (
    <Wrapper>
      <ul>
        <li>
          <Link to={supportLink}>Support</Link>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href={t("facebook_url")}>
            Facebook
          </a>
          <Dot />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={t("instagram_url")}
          >
            Instagram
          </a>
        </li>
        <li>
          <Link to={`/${t.url(node.data.travel_conditions.uid)}`}>
            {t("travel_conditions_text")}
          </Link>
          <Dot />
          <Link to={`/${t.url(node.data.privacy_policy.uid)}`}>
            {t("privacy_policy_text")}
          </Link>
        </li>
      </ul>
      <Logo>
        <img alt="logo" src={logo} />
      </Logo>
      <ul>
        <li>
          Powered by{" "}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.cabonline.com"
          >
            Cabonline
          </a>
        </li>
      </ul>
    </Wrapper>
  )
}

export default Footer
