import React from "react"
import styled from "styled-components"
import { gutter } from "../vars"

const Grid = styled.div`
  max-width: 1140px;
  min-height: ${gutter * 2}px;
  margin: 0 auto;
  padding: 0 ${gutter * 1}px;
`

export default Grid
