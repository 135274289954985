import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { gutter } from "../vars"
import media from "../media"
import debounce from "lodash/debounce"

const Hamburger = styled.div`
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
`
const HBox = styled.div`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
`
const HInner = styled.div`
  position: absolute;
  width: 40px;
  height: 4px;
  transition-timing-function: ease;
  transition-property: transform;
  border-radius: 4px;
  background-color: #fff;
  top: 50%;
  display: block;
  margin-top: -2px;

  transition-timing-function: ${p =>
    p.active
      ? "cubic-bezier(.215,.61,.355,1)"
      : "cubic-bezier(.55,.055,.675,.19)"};

  transition-duration: 0.22s;
  transition-delay: ${p => (p.active ? "0.12s" : "")};
  transform: rotate(${p => (p.active ? 225 : 0)}deg);

  &:before,
  &:after {
    position: absolute;
    width: 40px;
    height: 4px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: #fff;
    content: "";
    display: block;
  }

  &:before {
    top: -10px;
    top: ${p => (p.active ? 0 : "")};
    transition: ${p =>
      p.active
        ? "top .1s ease-out,opacity .1s ease-out .12s"
        : "top .1s ease-in .25s,opacity .1s ease-in;"};
    opacity: ${p => (p.active ? 0 : 1)};
  }
  &:after {
    bottom: ${p => (p.active ? 0 : -10)}px;
    transition: ${p =>
      p.active
        ? "bottom .1s ease-out,transform .22s cubic-bezier(.215,.61,.355,1) .12s"
        : ""};
    transform: ${p => (p.active ? "rotate(-90deg)" : "")};
  }
`
const BurgerWrap = styled.div`
  right: ${gutter}px;
  top: ${gutter * 2}px;
  z-index: 1000;
  position: fixed;
  cursor: pointer;
  display: none;
  top: ${p => (p.show ? gutter * 2 : -100)}px;
  transition: top 0.3s ease;

  ${media.mobileMenu`
    display:block;
  `}
`

const Burger = ({ active, onClick }) => {
  const lastScroll = useRef(0)
  const [showBurger, setShowBurger] = useState(true)

  const onScroll = debounce(() => {
    const st = window.pageYOffset || document.documentElement.scrollTop // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    if (st > lastScroll.current) {
      setShowBurger(false)
    } else {
      // upscroll code
      setShowBurger(true)
    }
    lastScroll.current = st <= 0 ? 0 : st
  }, 20)

  useEffect(() => {
    document.addEventListener("scroll", onScroll)
    return () => {
      document.removeEventListener("scroll", onScroll)
    }
  }, [])

  return (
    <BurgerWrap show={showBurger} onClick={onClick}>
      <Hamburger active={active}>
        <HBox active={active}>
          <HInner active={active} />
        </HBox>
      </Hamburger>
    </BurgerWrap>
  )
}

export default Burger
