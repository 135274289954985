import React from "react"
import GlobalStyle from "../GlobalStyle"
import styled from "styled-components"
import { SettingsContextProvider } from "../settingsContext"
import Pattern from "../components/Pattern"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Grid from "../components/Grid"
import Helmet from "react-helmet"

const Layout = styled.div`
  overflow: hidden;
`

const Content = styled.div`
  position: relative;
  z-index: 3;
`
const resetStyle = `
.js-reset {
  opacity: 1 !important;
  transform: none !important;
}
`

const PageLayout = ({ children, pageContext, location, data }) => {
  const isFrontpage = Boolean(data.prismicHome)
  return (
    <Layout>
      <Helmet>
        <link rel="shortcut icon" href="/favicon.png" />
      </Helmet>
      <Content>
        <SettingsContextProvider
          pathname={location.pathname}
          locale={pageContext.locale}
        >
          <Header noHeader={pageContext.noHeader} isFrontpage={isFrontpage} />
          {isFrontpage ? children : <Grid>{children}</Grid>}

          <Footer noHeader={pageContext.noHeader} />
        </SettingsContextProvider>
      </Content>
      {isFrontpage && <Pattern />}
      <GlobalStyle />
      <noscript>
        <style>{resetStyle}</style>
      </noscript>
    </Layout>
  )
}

export default PageLayout
