import { createGlobalStyle } from "styled-components"
import media from "./media"
import { primaryBg } from "./vars"

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Pangram';
        src: url('/fonts/Pangram-Regular.woff2') format('woff2'),
            url('/fonts/Pangram-Regular.woff') format('woff'),
            url('/fonts/Pangram-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Pangram';
        src: url('/fonts/Pangram-Light.woff2') format('woff2'),
            url('/fonts/Pangram-Light.woff') format('woff'),
            url('/fonts/Pangram-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Pangram';
        src: url('/fonts/Pangram-Bold.woff2') format('woff2'),
            url('/fonts/Pangram-Bold.woff') format('woff'),
            url('/fonts/Pangram-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }


    @font-face {
        font-family: 'Pangram';
        src: url('/fonts/Pangram-Black.woff2') format('woff2'),
            url('/fonts/Pangram-Black.woff') format('woff'),
            url('/fonts/Pangram-Black.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
        font-weight: 900;
    }

    html {
        box-sizing: border-box;
    }

    *, *::before, *::after {
        box-sizing: inherit;
    }

    * {
        
    }

    img {
        max-width:100%;
    }

    html,body {
        margin:0;
        background-color: ${primaryBg};
    }

    body {
       color: white;
        font-family: "Pangram", Helvetica, "Arial", sans-serif;
        line-height:1.4;
        font-size:18px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-image: linear-gradient(138deg, ${primaryBg}, #01423d);
        
        ${media.phone`
            font-size: 16px;
        `}
    }

    h1,h2,h3,h4 {
        margin:0;
        * {
            margin:0;
            padding:0;
        }
    }


    a {
        font-weight:bold;
        color:white;
    }

    h1 {
        font-weight: 300;
        font-size: 60px;

        ${media.tablet`
            font-size: 38px;
        `}

        ${media.phone`
            font-size: 34px;
        `}
    }

    h2 {
        font-size: 28px;


        ${media.tablet`
            font-size: 24px;
        `}

        ${media.phone`
            font-size: 22px;
        `}
    }
`

export default GlobalStyle
