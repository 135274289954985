// @flow

import React, { createContext, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

const SettingsContext = createContext({})

const defaultLocale = "no"
function SettingsContextProvider({ locale, children, pathname }) {
  const data = useStaticQuery(graphql`
    {
      allPrismicSettings {
        edges {
          node {
            lang
            dataString
          }
        }
      }
    }
  `)

  const [setting] =
    data.allPrismicSettings.edges
      .map(e => e.node)
      .filter(n => n.lang === locale)
      .map(n => JSON.parse(n.dataString)) || {}

  const t = key => {
    if (!setting) {
      return key
    }
    const match = setting[key]
    if (!match) {
      console.warn("cant find ", key)
      return key
    }

    return match
  }

  const langs = {
    "en-us": "en",
    "sv-se": "sv",
    "no": "no",
  }
  const lang = langs[locale] || locale

  t.url = path => {
    if (locale === defaultLocale) {
      return path
    }

    if (path === "/") {
      return `/${lang}`
    }

    return `/${lang}/${path}`
  }

  const currPath = pathname.replace("/en", "").replace("/sv", "").replace("/no", "")

  t.translate = targetLanguage => targetLanguage === "no" ? `/${currPath}` : `/${targetLanguage}${currPath}`

  t.pathPrefix = locale === "no" ? "/" : `/${lang}/`
  t.locale = locale
  t.lang = lang

  return (
    <SettingsContext.Provider value={t}>{children}</SettingsContext.Provider>
  )
}

const SettingsContextConsumer = SettingsContext.Consumer

const useSettingContext = () => useContext(SettingsContext)

export {
  useSettingContext,
  SettingsContext,
  SettingsContextProvider,
  SettingsContextConsumer,
}
